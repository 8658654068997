import React from 'react'
import { Blockquote } from 'components/Blockquote'
import { BulletText } from 'components/BulletText'
import { Content } from 'components/Content'
import { Download } from 'components/Download'
import { Features } from 'components/Features'
import { Hero, HeroModal, HeroSlider } from 'components/Heros'
import { Jobs } from 'components/Jobs'
import { KeyStats } from 'components/KeyStats'
import { Map } from 'components/Map'
import { PageLinks } from 'components/PageLinks'
import { TeaserTextImg, TeaserFullImg, TeaserProducts, TeaserBanner } from 'components/Teasers'

import { Timeline } from 'components/Timeline'

const Components = {
    blockquote: Blockquote,
    bulletText: BulletText,
    content: Content,
    downloads: Download,
    features: Features,
    hero: Hero,
    heroModal: HeroModal,
    heroSlider: HeroSlider,
    keyStats: KeyStats,
    inlineLinks: Jobs,
    map: Map,
    pageLinks: PageLinks,
    teaserTextImg: TeaserTextImg,
    teaserFullImg: TeaserFullImg,
    teaserProducts: TeaserProducts,
    teaserBanner: TeaserBanner,
    timeline: Timeline
}

export interface DynamicComponentProps {
    blok: any
    location: Location
    language: string
}

export const DynamicComponent: React.FC<DynamicComponentProps> = ({ blok, location, language }) => {
    if (typeof Components[blok.component] !== 'undefined') {
        const Component = Components[blok.component]
        return <Component blok={blok} key={blok._uid} location={location} language={language} />
    }

    return null
}
