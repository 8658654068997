import React from 'react'
import { Helmet } from 'react-helmet'

export interface SeoProps {
    language: string
    title: string
    description: string
}

export const Seo: React.FC<SeoProps> = ({ language, description, title }) => {
    return (
        <Helmet>
            <html lang={language} />
            <meta charSet="utf-8" />
            <body id="body" />
            <title>{title || 'Cordenka'} Cordenka</title>
            {description && <meta name="description" content={description}></meta>}
            {/* {url && <meta property="og:url" content={url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />} */}
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description} />}
            {/* {image && <meta property="og:image" content={image} />} */}
            {/* <meta name="twitter:card" content="summary_large_image" /> */}
            {/* {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />} */}
            {title && <meta name="twitter:title" content={title} />}
            {description && <meta name="twitter:description" content={description} />}
            {/* {seo && seo.image && <meta name="twitter:image" content={seo.image} />} */}
        </Helmet>
    )
}
