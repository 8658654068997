import React from 'react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { Link } from 'gatsby'

import { Query } from 'components/Teasers/QueryTeaserFullImg'
import { allNavigationQuery } from 'common/queries'
import { convertStoryContent } from 'hooks/useStoryblok'
import { Query as allPagesQuery } from 'components/Teasers/QueryTeaserFullImg'
import { useWindowSize } from 'hooks/useWindow'
import { Container } from 'components/Container'
import { Dropdown } from 'components/Dropdown'
import { animations, variant, transition } from 'components/Dropdown/animation'
import { MobileNav } from './MobileNav'

export interface MainHeaderProps {
    language: string
    location: Location
    isMenuInverted: boolean
    siblingId: string
}

export interface I_NavItem {
    uuid: string
    title: string
    childs: I_NavItem[]
    link: {
        id: string
    }
}

const listVariants: Variants = {
    initial: {
        y: '-30%',
        opacity: 0
    },
    animate: {
        y: '0',
        opacity: 1
    },
    exit: {
        y: '30%',
        opacity: 0
    }
}

export const MainHeader: React.FC<MainHeaderProps> = ({ language, isMenuInverted, siblingId }) => {
    const { width } = useWindowSize()
    const {
        allStoryblokEntry: { navigations }
    } = allNavigationQuery()

    const { allStories } = Query()
    const siblingLink = allStories.stories.find(({ story }) => story.uuid === siblingId)
    const siblingContent = convertStoryContent(siblingLink?.story ? siblingLink?.story : ('' as any))

    const {
        allStories: { stories }
    } = allPagesQuery()

    const hit = navigations.find(({ navigation }) => navigation.language === language)

    if (!hit) return null

    const convertedNavigation = convertStoryContent(hit.navigation)

    const renderLink = (linkId: string) => {
        const hit = stories.find(({ story }) => story.uuid === linkId)

        return hit ? (
            <Link
                className={`small fnt-utl-wei--600 ${location.pathname === '/' + hit.story.full_slug ? 'link--is-active' : ''}`}
                style={{ color: isMenuInverted ? '#fff' : '#000' }}
                to={`/${hit.story.full_slug}`}
            >
                {hit.story.name}
            </Link>
        ) : null
    }

    const renderDropdown = (items: I_NavItem[], title: string) => {
        const childLinks: any = []
        items.map(({ link }) => stories.find(({ story }) => story.uuid === link.id && childLinks.push(story)))

        return (
            <Dropdown title={title} isInverted={isMenuInverted}>
                <ul className="list gap-t-s">
                    {childLinks.map((item: any, index: number) => (
                        <motion.li
                            key={index}
                            className="dropdown__item"
                            variants={animations.item}
                            transition={{ ...transition, delay: 0.1 * (index + 0.5) }}
                            {...variant}
                        >
                            <Link className="small fnt-utl-wei--600" to={`/${item.full_slug}`}>
                                {item.name}
                            </Link>
                        </motion.li>
                    ))}
                </ul>
            </Dropdown>
        )
    }

    const renderNav = () => (
        <nav className="main-navigation" role="navigation" title="main-navigation">
            <h3 className="offscreen">Main Navigation</h3>
            <motion.ul
                className="list list--hori"
                key={location.pathname}
                initial={'initial'}
                animate={'animate'}
                exit={'initial'}
                variants={listVariants}
                transition={{ duration: 0.25, delay: 0.25, ease: 'easeInOut' }}
            >
                {convertedNavigation.content.item.map(({ uuid, title, childs, link }: I_NavItem, index: number) => (
                    <motion.li
                        key={uuid}
                        initial={'initial'}
                        animate={'animate'}
                        exit={'exit'}
                        variants={listVariants}
                        transition={{ duration: 0.25, delay: 0.1 * index }}
                        className={`list__item gap-r-m small fnt-utl-trn--uppercase`}
                    >
                        {childs.length === 0 ? renderLink(link.id) : renderDropdown(childs, title)}
                    </motion.li>
                ))}
            </motion.ul>
        </nav>
    )

    const renderLanguage = () => {
        return (
            <>
                <Link to={`/${siblingContent.full_slug}`} title={`${siblingContent.content.language}`}>
                    {siblingContent.content.language}
                </Link>{' '}
                / {language}
            </>
        )
    }

    return (
        <div className={`main-header ${isMenuInverted ? 'main-header--is-overlay' : ''}`}>
            <div className="main-header__content">
                <Container paddingTop={'l'} paddingBottom={'l'} isFluid={true}>
                    <div className="row">
                        <div className="col-8 col-sm-4 d-flex align-items-center">
                            <h1
                                style={{ color: isMenuInverted ? '#ffffff' : '#0566ff' }}
                                className={`logo h3 ${isMenuInverted ? 'logo--inverted' : ''}`}
                            >
                                <Link style={{ color: isMenuInverted ? '#ffffff' : '#0566ff' }} to={language === 'en' ? '/en' : '/'}>
                                    Cordenka
                                </Link>
                            </h1>
                            <br />
                            <div className="small" style={{ position: 'absolute', bottom: '8px' }}>
                                {renderLanguage()}
                            </div>
                        </div>
                        <div className="col-4 col-sm-8 d-flex align-items-center justify-content-end">
                            {width &&
                                (width > 770 ? renderNav() : <MobileNav language={language} navItems={convertedNavigation.content.item} />)}
                        </div>
                    </div>
                </Container>
            </div>

            {isMenuInverted && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, y: '-100%' }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    key={location.pathname}
                    className="main-header__overlay"
                />
            )}
        </div>
    )
}
