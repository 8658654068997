import React from 'react'
import { navigate } from 'gatsby'

import { convertStoryContent } from 'hooks/useStoryblok'
import { Link } from 'components/Link'
import { Container } from 'components/Container'
import { Divider } from 'components/Divider'
import { Text, TextProps } from 'components/Text'
import { LinkProps } from 'components/Link'
import { HeroProps } from 'components/Heros/Hero'
import { VisibilityWrapper } from 'components/VisibilityWrapper'
import { Query } from './QueryTeaserFullImg'
import { variantsText, variantsDivider, variantsCover, variantsImg, transition, transitionImg } from './animations/TeaserFullImg'

export interface TeaserFullImgProps {
    blok: any
    headline: string
    subline: string
    description: string
    language: string
    products: string[]
    learnMore?: {
        isLink: boolean
        title: string
        slug: string
    }
    img: {
        src: string
        alt: string
    }
}

export const TeaserFullImg: React.FC<TeaserFullImgProps> = ({ blok, language, products }) => {
    const {
        allStories: { stories }
    } = Query()
    const storyPage = stories.find(({ story }) => blok.link === story.uuid)

    if (!storyPage) {
        return null
    }

    const story = convertStoryContent(storyPage.story) as { content: { content: any[] } }
    const heroContent: HeroProps = story.content.content[0]
    const content: { [key: string]: TextProps } = {
        headline: {
            content: heroContent.headline,
            tag: 'h2',
            classes: 'h1 fnt-utl-wei--300 fnt-utl-clr--inverted',
            paddingTop: 'm',
            paddingBottom: 'xl'
        },
        subline: {
            content: heroContent.meta,
            tag: 'h3',
            classes: 'body fnt-utl-wei--500 fnt-utl-trn--uppercase fnt-utl-clr--inverted',
            paddingTop: 's',
            paddingBottom: 'm'
        },
        description: {
            content: heroContent.description || '',
            tag: 'p',
            classes: 'body fnt-utl--legible fnt-utl-wei--500 fnt-utl-clr--inverted gap-b-l',
            paddingTop: 'l',
            paddingBottom: 'm'
        }
    }

    const learnMore: LinkProps = {
        title: language === 'en' ? 'Learn More' : 'Mehr Erfahren',
        slug: storyPage.story.full_slug,
        withIcon: true,
        classes: 'fnt-utl-clr--inverted'
    }

    const renderText = (text: TextProps) => <Text {...text} />

    const renderLink = (link: LinkProps) => <Link {...link} />

    const renderContent = () => (
        <div className="teaser-full-img__content" onClick={() => navigate(`/${storyPage.story.full_slug}`)}>
            <Container paddingTop={'3xl'} paddingBottom={'3xl'} isFluid={false}>
                <div className="row">
                    <div className="col-12">
                        <VisibilityWrapper styles={{ height: 'auto' }} variants={variantsText} transition={{ ...transition, delay: 1 }}>
                            {renderText(content.subline)}
                        </VisibilityWrapper>
                        <VisibilityWrapper
                            styles={{ height: 'auto' }}
                            variants={variantsDivider}
                            transition={{ ...transition, delay: 1, duration: 0.75 }}
                        >
                            <Divider />
                        </VisibilityWrapper>
                        <VisibilityWrapper styles={{ height: 'auto' }} variants={variantsText} transition={{ ...transition, delay: 1.35 }}>
                            {renderText(content.headline)}
                        </VisibilityWrapper>
                        <VisibilityWrapper styles={{ height: 'auto' }} variants={variantsText} transition={{ ...transition, delay: 1.35 }}>
                            <p className={`${content.description.classes}`}>{content.description.content}</p>
                        </VisibilityWrapper>
                        <VisibilityWrapper styles={{ height: 'auto' }} variants={variantsText} transition={{ ...transition, delay: 1.35 }}>
                            {learnMore ? renderLink(learnMore) : ''}
                        </VisibilityWrapper>
                    </div>
                </div>
            </Container>
        </div>
    )

    return (
        <div className="teaser-full-img">
            {renderContent()}
            <VisibilityWrapper
                styles={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                variants={variantsImg}
                transition={{ ...transitionImg, delay: 0.35 }}
            >
                <img className="teaser-full-img__img" src={heroContent.image.filename} />
            </VisibilityWrapper>
            <VisibilityWrapper
                styles={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                variants={variantsCover}
                transition={{ ...transition, delay: 1 }}
            >
                <div className="teaser-full-img__cover"></div>
            </VisibilityWrapper>
        </div>
    )
}
