import React from 'react'
import { Link } from 'gatsby'

import { convertStoryContent } from 'hooks/useStoryblok'
import { ButtonRound } from 'components/Buttons'
import { Container } from 'components/Container'
import { Divider } from 'components/Divider'
import { Image } from 'components/Image'
import { Text } from 'components/Text'
import { variantsText, variantsDivider, transitionImg, transition } from 'components/Teasers/animations/TeaserFullImg'
import { VisibilityWrapper } from 'components/VisibilityWrapper'
import { Query } from 'components/Teasers/QueryTeaserFullImg'

export interface PageLinksProps {
    blok: {
        headline?: string
        links: {
            id: string
        }[]
    }
    location: Location
    language: string
    pageLinks: string[]
}

export const PageLinks: React.FC<PageLinksProps> = ({ blok }) => {
    const { headline, links } = blok
    const {
        allStories: { stories }
    } = Query()

    const renderLinks = (item: { link: { id: string } }, index: number) => {
        const filteredStory = stories.find(({ story }) => story.uuid === item.link.id) as any

        if (!filteredStory) return

        const convertedStory = convertStoryContent(filteredStory.story)
        const {
            full_slug,
            content: { content }
        } = convertedStory

        return (
            <div key={index} className="page-links__item">
                <Link to={`/${full_slug}`}>
                    <div className="page-links__item-inner">
                        <div className="page-links__item__content">
                            <VisibilityWrapper styles={{ height: 'auto' }} variants={variantsText} transition={{ ...transitionImg }}>
                                <Text
                                    tag={'p'}
                                    classes={'body fnt-utl-trn--uppercase fnt-utl-clr--inverted'}
                                    content={content[0].meta}
                                    paddingTop={'none'}
                                    paddingBottom={'s'}
                                />
                            </VisibilityWrapper>
                            <VisibilityWrapper styles={{ height: 'auto' }} variants={variantsDivider} transition={{ ...transitionImg }}>
                                <Divider />
                            </VisibilityWrapper>
                            <VisibilityWrapper
                                styles={{ height: 'auto' }}
                                variants={variantsText}
                                transition={{ ...transitionImg, delay: 0.75 }}
                            >
                                <Text
                                    tag={'h3'}
                                    classes={'h3 fnt-utl-clr--inverted'}
                                    content={content[0].headline}
                                    paddingTop={'m'}
                                    paddingBottom={'none'}
                                />
                            </VisibilityWrapper>
                        </div>
                        <div className="page-links__item__footer">
                            <VisibilityWrapper
                                styles={{ height: 'auto' }}
                                variants={variantsText}
                                transition={{ ...transitionImg, delay: 0.25 }}
                            >
                                <ButtonRound title="→" delegateClick={() => {}} />
                            </VisibilityWrapper>
                        </div>
                    </div>
                    <img src={content[0].image.filename} className={'page-links__image'} />
                </Link>
            </div>
        )
    }

    return (
        <div className="page-links">
            {headline ? (
                <Container isFluid={true} paddingTop={'none'} paddingBottom={'none'}>
                    <div className="row">
                        <div className="col">
                            <Text tag={'h2'} classes="fnt-utl-clr--subtle" content={headline} paddingTop={'2xl'} paddingBottom={'xl'} />
                        </div>
                    </div>
                </Container>
            ) : (
                ''
            )}
            <div className="page-links__items">{links.map((item, index) => renderLinks(item, index))}</div>
        </div>
    )
}
