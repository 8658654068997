import React from 'react'
import { Page } from 'components/Page'

export interface ModalProps {
    content: any
    location: Location
}

export const Modal: React.FC<ModalProps> = ({ content, location }) => {
    return <Page blok={content} location={location} />
}
