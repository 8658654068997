import { Variants } from 'framer-motion'

export const variants: Variants = {
    inOut: {
        opacity: 0,
        zIndex: 1,
        scale: 1.15
    },
    entered: {
        zIndex: 1,
        opacity: 1,
        scale: 1
    },
    exit: {
        zIndex: 0,
        opacity: 0,
        scale: 1.15
    }
}
