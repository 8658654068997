import React, { useEffect, useRef, useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from '!mapbox-gl'
import { Container } from 'components/Container'

mapboxgl.accessToken = 'pk.eyJ1IjoiYW11bnNlbiIsImEiOiJja3BlMHRvOWcxcnFhMnlvZ3FidGl3b2tiIn0.AYOnitwWCBu8XfAgHW-6Ag'
// export interface MapProps { }

export interface MapProps {}

export const Map: React.FC<MapProps> = () => {
    const mapContainer = useRef(null)
    const [map, setMap] = useState(null)
    const [lat, setLat] = useState(49.8300236)
    const [lng, setLng] = useState(9.1482309)
    const [zoom, setZoom] = useState(2)

    useEffect(() => {
        const initializeMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/amunsen/ckp52nffx32gr18mk48a8k80i',
                center: [30.6417486, 46.4599506],
                zoom: zoom
            })

            map.on('load', () => {
                setMap(map)
                map.resize()
            })
            const popupIco = new mapboxgl.Popup({ offset: 25 }).setHTML(
                'Cordenka GmbH & Co. KG<br/>Cordenka Headquarter Rayon Production & Converting Obernburg a. Main, Germany'
            )
            const popupCordus = new mapboxgl.Popup({ offset: 25 }).setText('Cordus GmbH Converting Mühlhausen/Thüringen, Germany')
            const popupPoland = new mapboxgl.Popup({ offset: 25 }).setText(
                'Cordenka Gorzów Sp. z o.o. Converting Gorzów Wielkopolski, Poland'
            )
            const popupShanghai = new mapboxgl.Popup({ offset: 25 }).setText(
                'Cordenka Shanghai Consultant Co. Ltd. Representative Office Shanghai, China'
            )
            const popupQingdao = new mapboxgl.Popup({ offset: 25 }).setText(
                'Cordenka Qingdao New Materials Co. Ltd. Sales Office Qingdao, China'
            )

            new mapboxgl.Marker({ color: '#1E3873' })
                .setLngLat([lng, lat])
                .setPopup(popupIco)
                .addTo(map)
            new mapboxgl.Marker({ color: '#1E3873' })
                .setLngLat([10.4881513, 51.21771])
                .setPopup(popupCordus)
                .addTo(map)
            new mapboxgl.Marker({ color: '#1E3873' })
                .setLngLat([15.1832163, 52.7313227])
                .setPopup(popupPoland)
                .addTo(map)
            new mapboxgl.Marker({ color: '#1E3873' })
                .setLngLat([120.0846507, 36.1357443])
                .setPopup(popupQingdao)
                .addTo(map)
            new mapboxgl.Marker({ color: '#1E3873' })
                .setLngLat([121.1965795, 31.2240453])
                .setPopup(popupShanghai)
                .addTo(map)
        }

        if (!map) initializeMap({ setMap, mapContainer })
    })

    return (
        <Container paddingBottom={'none'} paddingTop={'s'} isFluid={true}>
            <div className="row">
                <div className="row-12">
                    <div ref={mapContainer} className="map-container" />
                </div>
            </div>
        </Container>
    )
}

export default Map
