import React from 'react'
import { I_Component } from 'common/types/Component'

export interface ContainerProps extends I_Component {
    isFluid: boolean
    children: React.ReactNode
}

export const Container: React.FC<ContainerProps> = ({ children, isFluid, paddingTop, paddingBottom }) => {
    return (
        <div className={`gap-t-${paddingTop} gap-b-${paddingBottom} container-wrapper`}>
            <div className={`container${isFluid ? '-fluid' : ''}`}>{children}</div>
        </div>
    )
}
