import React, { useContext } from 'react'
import { motion, useTransform, MotionValue } from 'framer-motion'
import { navigate } from 'gatsby'

import { GlobalContext } from 'context/Global'
import { ButtonRound } from 'components/Buttons'
import { Text } from 'components/Text'
import { Divider } from 'components/Divider'

export interface HeroSliderPanelProps {
    x: MotionValue<number>
    xInput: number[]
    meta: string
    slug?: string
    headline: string
    isPaused: boolean
    delegateMouseOver: () => void
    delegateMouseLeave: () => void
    delegateClick: (number: number) => void
}

export const HeroSliderPanel: React.FC<HeroSliderPanelProps> = ({
    x,
    xInput,
    meta,
    headline,
    slug,
    isPaused,
    delegateClick,
    delegateMouseOver,
    delegateMouseLeave
}) => {
    const { language } = useContext(GlobalContext)
    const panePos = useTransform(x, xInput, [-50, 0, 50])
    const paneOpacity = useTransform(x, xInput, [0, 1, 0])

    return (
        <div className="hero-slider__panel-wrapper">
            <motion.div
                initial={false}
                animate={'center'}
                className="hero-slider__panel"
                onMouseOver={() => delegateMouseOver()}
                onMouseLeave={() => delegateMouseLeave()}
                style={{
                    y: panePos,
                    opacity: paneOpacity
                }}
            >
                <div className="hero-slider__panel__clicker" onClick={() => slug && navigate(`/${slug}`)}></div>
                <Text
                    tag={'h3'}
                    classes="small fnt-utl-trn--uppercase fnt-utl-clr--inverted"
                    content={meta}
                    paddingTop={'none'}
                    paddingBottom={'m'}
                />
                <Divider />
                <Text tag={'h2'} classes="h3 fnt-utl-clr--inverted" content={headline} paddingTop={'m'} paddingBottom={'none'} />
                <div className="hero-slider__panel__footer">
                    <ButtonRound delegateClick={() => delegateClick(-1)} title={`←`} />
                    <ButtonRound delegateClick={() => delegateClick(1)} title={`→`} />
                </div>
                <div className="hero-slider__timeline">
                    <div className={`hero-slider__timeline__index ${!isPaused ? 'hero-slider__timeline__index--is-paused' : ''}`}></div>
                </div>
            </motion.div>
        </div>
    )
}
