import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { useWindowSize } from 'hooks/useWindow'
import { sbClient } from 'hooks/useStoryblok'
import { ButtonRound } from 'components/Buttons'
import { Text, TextProps } from 'components/Text'
import { Container } from 'components/Container'
import { Table } from 'components/Table'
import { GraphInline } from './Graph'

import { Illustrations, T_IlluType } from './Illustrations'

export interface I_FeatureItems {
    illuType: T_IlluType
    headline: string
    content: any
    titleOne: string
    titleTwo: string
    descriptionOne: string
    descriptionTwo: string
    tables?: {
        _uid: string
        tableHeader: string
        tableSubline?: string
        tableCaption: string
        header: string
        subline: string
        caption: string
        item?: {
            _uid: string
            thead: any[]
            tbody: any[]
            values: number[]
        }
    }[]
}

export interface FeaturesProps {
    blok: {
        items: I_FeatureItems[]
    }
    featureItems: I_FeatureItems[]
    language: string
}

export const Features: React.FC<FeaturesProps> = ({ blok, featureItems, language }) => {
    const { items } = blok
    const { width } = useWindowSize()
    const [isVisible, setIsVisible] = useState<number | null>(null)

    const renderKeyStats = (item: I_FeatureItems, index: number) => {
        const text: { [key: string]: TextProps } = {
            headline: {
                tag: 'h3',
                classes: 'h4 fnt-utl-wei--500',
                content: item.headline,
                paddingTop: 'none',
                paddingBottom: '3xl'
            }
        }

        return (
            <div key={index} className="key-stat key-stat--narrow" onClick={() => setIsVisible(isVisible === index ? null : index)}>
                <div className="key-stat__body">
                    <Text {...text.headline} />
                </div>
                <div className="key-stat__footer d-flex justify-content-end">
                    <ButtonRound
                        classes="key-stat__button button-round--inverted"
                        title={isVisible === index ? '×' : '+'}
                        delegateClick={() => {}}
                    />
                </div>
                <Illustrations classes="key-stat__illustration" type={item.illuType} />
            </div>
        )
    }

    const renderItems = (item: I_FeatureItems, index: number) => (
        <div key={index} className="col-4">
            {renderKeyStats(item, index)}
        </div>
    )

    const renderDragItem = (featureItems: I_FeatureItems[]) => {
        return (
            <div style={{ width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}>
                <motion.div
                    drag={'x'}
                    dragConstraints={{ left: -350, right: 16 }}
                    style={{ width: '660px', paddingLeft: 16 }}
                    className="d-flex justify-content-between"
                >
                    {featureItems.map((item, index) => (
                        <div key={index} className="d-flex" style={{ width: '200px', height: '200px' }}>
                            {renderKeyStats(item, index)}
                        </div>
                    ))}
                </motion.div>
            </div>
        )
    }

    return (
        <div className="feature gap-t-3xl">
            <div className="feature__keys">
                {width && width < 600 ? (
                    renderDragItem(items)
                ) : (
                    <Container paddingTop={'none'} paddingBottom={'none'} isFluid={true}>
                        <div className="row">{items.map((item, index) => renderItems(item, index))}</div>
                    </Container>
                )}
            </div>
            <AnimatePresence exitBeforeEnter>
                {isVisible !== null && (
                    <motion.div
                        className="feature__content"
                        initial={{ opacity: 0, y: '20%', height: 0 }}
                        animate={{ opacity: 1, y: '0%', height: 'auto' }}
                        exit={{ opacity: 0, y: '20%', height: 0 }}
                        transition={{ ease: 'easeInOut', duration: 1 }}
                    >
                        <Container paddingTop={'2xl'} paddingBottom={'xl'} isFluid={true}>
                            <div className="row">
                                <div className="col-12">
                                    {isVisible === 0 && (
                                        <motion.div
                                            initial={{ opacity: 0, y: '30%' }}
                                            animate={{ opacity: 1, y: '0%' }}
                                            exit={{ opacity: 0, y: '30%' }}
                                        >
                                            <motion.div
                                                className={
                                                    'gap-t-xl gap-b-xl fnt-utl-clr--inverted fnt-utl-line-height--text fnt-utl-fam--recia'
                                                }
                                                initial={{ opacity: 0, y: '30%' }}
                                                animate={{ opacity: 1, y: '0%' }}
                                                exit={{ opacity: 0, y: '30%' }}
                                                transition={{ ease: 'easeInOut', duration: 0.75, delay: 0.25 }}
                                                dangerouslySetInnerHTML={{
                                                    __html: sbClient.richTextResolver.render(items[isVisible].content)
                                                }}
                                            />
                                            <motion.div
                                                initial={{ opacity: 0, y: '30%' }}
                                                animate={{ opacity: 1, y: '0%' }}
                                                exit={{ opacity: 0, y: '30%' }}
                                                transition={{ ease: 'easeInOut', duration: 0.75, delay: 0.5 }}
                                            >
                                                {items[isVisible].titleOne && (
                                                    <h4 className="gap-b-l fnt-utl-clr--inverted">{items[isVisible].titleOne}</h4>
                                                )}
                                                {items[isVisible].descriptionOne && (
                                                    <p className="body fnt-utl-clr--inverted gap-b-xl">{items[isVisible].descriptionOne}</p>
                                                )}
                                                <GraphInline type={'graph1'} />
                                                {items[isVisible].titleTwo && (
                                                    <h4 className="gap-b-l fnt-utl-clr--inverted">{items[isVisible].titleTwo}</h4>
                                                )}
                                                {items[isVisible].descriptionTwo && (
                                                    <p className="body fnt-utl-clr--inverted gap-b-xl">{items[isVisible].descriptionTwo}</p>
                                                )}
                                                <GraphInline type={'graph2'} />
                                            </motion.div>
                                        </motion.div>
                                    )}
                                    {isVisible === 1 && (
                                        <motion.div
                                            initial={{ opacity: 0, y: '30%' }}
                                            animate={{ opacity: 1, y: '0%' }}
                                            exit={{ opacity: 0, y: '30%' }}
                                        >
                                            <motion.div
                                                className={'gap-t-xl text gap-b-xl fnt-utl-clr--inverted fnt-utl-line-height--text'}
                                                initial={{ opacity: 0, y: '30%' }}
                                                animate={{ opacity: 1, y: '0%' }}
                                                exit={{ opacity: 0, y: '30%' }}
                                                transition={{ ease: 'easeInOut', duration: 0.75, delay: 0.25 }}
                                                dangerouslySetInnerHTML={{
                                                    __html: sbClient.richTextResolver.render(items[isVisible].content)
                                                }}
                                            />
                                            <motion.div
                                                initial={{ opacity: 0, y: '30%' }}
                                                animate={{ opacity: 1, y: '0%' }}
                                                exit={{ opacity: 0, y: '30%' }}
                                                transition={{ ease: 'easeInOut', duration: 0.75, delay: 0.5 }}
                                            >
                                                {items[isVisible].titleOne && (
                                                    <h4 className="gap-b-l fnt-utl-clr--inverted">{items[isVisible].titleOne}</h4>
                                                )}
                                                {items[isVisible].descriptionOne && (
                                                    <p className="body fnt-utl-clr--inverted gap-b-xl">{items[isVisible].descriptionOne}</p>
                                                )}
                                            </motion.div>
                                            <motion.div
                                                initial={{ opacity: 0, y: '30%' }}
                                                animate={{ opacity: 1, y: '0%' }}
                                                exit={{ opacity: 0, y: '30%' }}
                                                transition={{ ease: 'easeInOut', duration: 0.75, delay: 0.5 }}
                                            >
                                                <GraphInline type={'graph3'} />
                                                <GraphInline type={'graph4'} />
                                            </motion.div>
                                        </motion.div>
                                    )}
                                    {isVisible === 2 && (
                                        <motion.div
                                            initial={{ opacity: 0, y: '30%' }}
                                            animate={{ opacity: 1, y: '0%' }}
                                            exit={{ opacity: 0, y: '30%' }}
                                        >
                                            <motion.div
                                                className={'gap-t-xl gap-b-xl fnt-utl-clr--inverted fnt-utl-line-height--text'}
                                                initial={{ opacity: 0, y: '30%' }}
                                                animate={{ opacity: 1, y: '0%' }}
                                                exit={{ opacity: 0, y: '30%' }}
                                                transition={{ ease: 'easeInOut', duration: 0.75, delay: 0.25 }}
                                                dangerouslySetInnerHTML={{
                                                    __html: sbClient.richTextResolver.render(items[isVisible].content)
                                                }}
                                            />
                                            <motion.div
                                                initial={{ opacity: 0, y: '30%' }}
                                                animate={{ opacity: 1, y: '0%' }}
                                                exit={{ opacity: 0, y: '30%' }}
                                                transition={{ ease: 'easeInOut', duration: 0.75, delay: 0.5 }}
                                            >
                                                {items[isVisible].tables &&
                                                    items[isVisible].tables?.map((item) => {
                                                        return (
                                                            <div key={item._uid} className="gap-t-xl gap-b-xl">
                                                                <h3
                                                                    className={`text fnt-utl-trn--uppercase fnt-utl-clr--inverted ${
                                                                        item.subline ? '' : 'gap-b-l'
                                                                    }`}
                                                                >
                                                                    {item.header}
                                                                </h3>
                                                                {item.subline ? (
                                                                    <h4 className="body fnt-utl-trn--uppercase fnt-utl-clr--inverted gap-b-l">
                                                                        {item.subline}
                                                                    </h4>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {item.item && <Table tHead={item.item.thead} tBody={item.item.tbody} />}
                                                                <div
                                                                    className="body fnt-utl-clr--inverted gap-t-xl"
                                                                    dangerouslySetInnerHTML={{ __html: item.caption }}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                            </motion.div>
                                        </motion.div>
                                    )}
                                </div>
                            </div>
                        </Container>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}
