import { useStaticQuery, graphql } from 'gatsby'

export interface I_Navigation {
    navigation: {
        uuid: string
        language: string
        name: string
        content: any
    }
}

export interface I_AllPages {
    allStoryblokEntry: {
        navigations: I_Navigation[]
    }
}

export function allNavigationQuery(): I_AllPages {
    return useStaticQuery(graphql`
        query AllNavigation {
            allStoryblokEntry(filter: { field_component: { eq: "navigation" } }) {
                navigations: edges {
                    navigation: node {
                        uuid
                        language: field_language_string
                        name
                        content
                    }
                }
            }
        }
    `)
}
