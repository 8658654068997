import React, { useEffect } from 'react'
import { motion, useAnimation, Variants, Transition } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

export interface VisibilityWrapperProps {
    children: React.ReactNode
    variants: Variants
    transition: Transition
    styles?: any
}

export const VisibilityWrapper: React.FC<VisibilityWrapperProps> = ({ children, variants, transition, styles }) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [controls, inView])

    return (
        <motion.div
            style={styles ? { ...styles } : { height: '100%' }}
            animate={controls}
            ref={ref}
            initial="hidden"
            transition={transition}
            variants={variants}
        >
            {children}
        </motion.div>
    )
}
