import React from 'react'

import { Container } from 'components/Container'
import { Text } from 'components/Text'

export interface HeroModalProps {
    blok: {
        meta: string
        headline: string
        description: string
        image: {
            filename: string
            alt: string
        }
    }
}

export const HeroModal: React.FC<HeroModalProps> = ({ blok }) => {
    const { meta, headline, description, image } = blok
    return (
        <>
            <div className="modal__hero">
                <div className="modal__hero__content">
                    <div style={{ position: 'relative', zIndex: 4 }}>
                        <Container paddingTop={'none'} paddingBottom={'none'} isFluid={true}>
                            <div className="row">
                                <div className="col-12">
                                    <Text
                                        tag={'h2'}
                                        classes="h3 fnt-utl-wei--300 fnt-utl-clr--inverted"
                                        content={meta}
                                        paddingTop={'none'}
                                        paddingBottom={'s'}
                                    />
                                    <Text
                                        tag={'h3'}
                                        classes="h3 fnt-utl-wei--600 fnt-utl-clr--inverted"
                                        content={headline}
                                        paddingTop={'none'}
                                        paddingBottom={'xl'}
                                    />
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <img className="modal__hero__img" src={image.filename} alt={image.alt} />
            </div>
            {description && (
                <Container paddingTop={'2xl'} paddingBottom={'xl'} isFluid={false}>
                    <div className="row">
                        <div className="col-12 h4 fnt-utl-fam--recia fnt-utl-line-height--text">{description}</div>
                    </div>
                </Container>
            )}
        </>
    )
}
