import { useStaticQuery, graphql } from 'gatsby'

export interface I_Navigation {
    footer: {
        uuid: string
        language: string
        name: string
        content: any
    }
}

export interface I_AllFooter {
    allStoryblokEntry: {
        footers: I_Navigation[]
    }
}

export function allFooterQuery(): I_AllFooter {
    return useStaticQuery(graphql`
        query AllFooter {
            allStoryblokEntry(filter: { field_component: { eq: "footer" } }) {
                footers: edges {
                    footer: node {
                        uuid
                        language: field_language_string
                        name
                        content
                    }
                }
            }
        }
    `)
}
