import React from 'react'
import { Link } from 'gatsby'

import { Query as allPagesQuery } from 'components/Teasers/QueryTeaserFullImg'
import { allFooterQuery } from 'common/queries/'
import { Container } from 'components/Container'
import { Dropdown } from 'components/Dropdown'
import { Text } from 'components/Text'
import { convertStoryContent } from 'hooks/useStoryblok'

import { I_FooterColumn } from './StaticQuery'

export interface FooterProps {
    language: string
    location: Location
    siblingLink?: string
}

export const Footer: React.FC<FooterProps> = ({ language, location, siblingLink }) => {
    const {
        allStoryblokEntry: { footers }
    } = allFooterQuery()

    const hit = footers.find(({ footer }) => footer.language === language)

    if (hit === undefined) return null

    const footerContent = convertStoryContent(hit.footer).content

    const renderLanguageSelection = (link: string) => {
        return (
            <div className="gap-t-l">
                <Dropdown title={language} isInverted={true}>
                    <ul className="list gap-t-s">
                        <li className="dropdown__item">
                            <Link to={`/${link}`} title={'Page Sibling'}>
                                {link}
                            </Link>
                        </li>
                    </ul>
                </Dropdown>
            </div>
        )
    }

    const renderColumnItem = (item: any, index: number) => {
        const {
            allStories: { stories }
        } = allPagesQuery()
        const hit = stories.find(({ story }) => story.uuid === item.link.id)

        if (hit === undefined) return null
        const { full_slug } = hit.story

        return (
            <li key={index} className="body fnt-utl-clr--inverted gap-t-s">
                <Link className="fnt-utl-clr--inverted gap-t-s gap-b-s footer__link" to={`/${full_slug}`}>
                    {item.title}
                </Link>
            </li>
        )
    }

    const renderColumn = (column: I_FooterColumn, index: number) => {
        return (
            <div key={index} className="col col-sm-4 col-xl-3">
                <Text
                    tag={'p'}
                    classes={'body fnt-utl-trn--uppercase fnt-utl-wei--400 fnt-utl-clr--inverted-subtle'}
                    content={column.title}
                    paddingTop={'2xl'}
                    paddingBottom={'none'}
                />
                <ul className="list gap-t-l">{column.links.map((link: any, index: number) => renderColumnItem(link, index))}</ul>
                {index === 0 && siblingLink && renderLanguageSelection(siblingLink)}
            </div>
        )
    }

    return (
        <footer className="footer gap-t-xl gap-b-xl">
            <h2 className="offscreen">Footer</h2>
            <Container paddingTop={'m'} paddingBottom={'xl'} isFluid={false}>
                <div className="row gx-5">
                    {footerContent.columns.map((column: any, index: any) => renderColumn(column, index))}
                    <div className="col col-sm-4 col-xl-3">
                        <Text
                            tag={'p'}
                            classes={'body fnt-utl-trn--uppercase fnt-utl-wei--400 fnt-utl-clr--inverted-subtle'}
                            content={language === 'en' ? 'Contact' : 'Kontakt'}
                            paddingTop={'2xl'}
                            paddingBottom={'none'}
                        />
                        <ul className="list gap-t-l">
                            <li className="body fnt-utl-clr--inverted gap-t-s">
                                <a className="fnt-utl-clr--inverted footer__link" href="tel:+49602281-0">
                                    +49 (0) 6022 81-0
                                </a>
                            </li>
                            <li className="body fnt-utl-clr--inverted gap-t-s">
                                <a className="fnt-utl-clr--inverted footer__link" href="mailto:info@cordenka.com">
                                    info@cordenka.com
                                </a>
                            </li>
                            <li className="body fnt-utl-clr--inverted gap-t-l">
                                <a
                                    className="fnt-utl-clr--inverted footer__link"
                                    href="https://www.linkedin.com/company/cordenka-gmbh-&-co-kg/"
                                    target="_blank"
                                >
                                    <svg
                                        style={{ width: 40, height: 40 }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                    >
                                        <path d="M18.3362 18.339H15.6707V14.1622C15.6707 13.1662 15.6505 11.8845 14.2817 11.8845C12.892 11.8845 12.6797 12.9683 12.6797 14.0887V18.339H10.0142V9.75H12.5747V10.9207H12.6092C12.967 10.2457 13.837 9.53325 15.1367 9.53325C17.8375 9.53325 18.337 11.3108 18.337 13.6245V18.339H18.3362ZM7.00373 8.57475C6.14573 8.57475 5.45648 7.88025 5.45648 7.026C5.45648 6.1725 6.14648 5.47875 7.00373 5.47875C7.85873 5.47875 8.55173 6.1725 8.55173 7.026C8.55173 7.88025 7.85798 8.57475 7.00373 8.57475ZM8.34023 18.339H5.66723V9.75H8.34023V18.339ZM19.6697 3H4.32923C3.59498 3 3.00098 3.5805 3.00098 4.29675V19.7033C3.00098 20.4202 3.59498 21 4.32923 21H19.6675C20.401 21 21.001 20.4202 21.001 19.7033V4.29675C21.001 3.5805 20.401 3 19.6675 3H19.6697Z"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 d-flex align-items-end justify-content-end gap-t-xl">
                        <div className="logo h2 fnt-utl-clr--brand-one">Cordenka</div>
                    </div>
                    <div className="col-12 d-flex align-items-end justify-content-end">
                        <div className="fnt-utl-clr--inverted small">PREMIUM RAYON REINFORCEMENT</div>
                    </div>
                </div>
            </Container>
        </footer>
    )
}
