import { Variants, Transition } from 'framer-motion'

export const variantsCover: Variants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1
    }
}

export const variantsImg: Variants = {
    hidden: {
        opacity: 0,
        scale: 1.2
    },
    visible: {
        opacity: 1,
        scale: 1
    }
}

export const variantsText: Variants = {
    hidden: {
        opacity: 0,
        y: '25%'
    },
    visible: {
        opacity: 1,
        y: '0%'
    }
}

export const variantsDivider: Variants = {
    hidden: {
        opacity: 0,
        width: '0%'
    },
    visible: {
        opacity: 1,
        width: '100%'
    }
}

export const transition: Transition = {
    ease: 'easeInOut',
    duration: 0.75
}

export const transitionImg: Transition = {
    ease: 'easeInOut',
    duration: 1.5
}
