import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import { FoundationLayout, MainLayout } from 'components/Layout'
import { Page } from 'components/Page'

import { useStoryblok } from 'hooks/useStoryblok'
import { GlobalContext } from 'context/Global'
import { Seo } from 'components/Seo'

import 'common/styles/main.scss'

export interface PageAssemblerProps {
    data: any
    location: Location
    language: string
}

const PageAssembler: React.FC<PageAssemblerProps> = ({ data, location, language }) => {
    if (typeof window === `undefined`) return null

    const { setGlobal } = useContext(GlobalContext)
    const story = useStoryblok(data.story, location)

    useEffect(() => {
        const isInverted = story.content.isMenuInverted as boolean
        const id = story.content._uid as string
        const title = story.content.Seo.title as string
        const description = story.content.Seo.description as string
        const pathname = story.full_slug as string

        setGlobal({ pathname, id, isInverted, title, description, language: story.language })
    }, [])

    return (
        <>
            <Seo language={story.language} title={story.content.Seo.title} description={story.content.Seo.description} />
            <FoundationLayout
                language={story.language}
                location={location}
                isMenuInverted={story.content.isMenuInverted}
                siblingId={story.content.sibling.id}
            >
                <MainLayout location={location} siblingId={story.content.sibling.id} language={story.language}>
                    <main>
                        <Page blok={story.content} location={location} language={story.language} />
                    </main>
                </MainLayout>
            </FoundationLayout>
        </>
    )
}

export default PageAssembler

export const graphQuery = graphql`
    query ($fullSlug: String) {
        story: storyblokEntry(full_slug: { eq: $fullSlug }) {
            lang
            language: field_language_string
            name
            content
            full_slug
            uuid
        }
    }
`
