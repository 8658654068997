import React, { useState, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useOuterClick } from 'functions/useOuterClick'

import { animations, variant } from './animation'

export interface DropdownProps {
    title: string
    isInverted: boolean
    children: React.ReactNode
}

export const Dropdown: React.FC<DropdownProps> = ({ title, children, isInverted }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const refDropdown = useRef<HTMLDivElement>(null)

    useOuterClick(() => {
        isOpen && setIsOpen(false)
    }, refDropdown)
    return (
        <div className="dropdown">
            <div onClick={() => setIsOpen(!isOpen)} className="dropdown__title">
                <span className={`fnt-utl-wei--600 ${isInverted ? 'fnt-utl-clr--inverted' : ''}`}>{title}</span>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        ref={refDropdown}
                        onClick={() => setIsOpen(!isOpen)}
                        className="dropdown__pane"
                        variants={animations.pane}
                        {...variant}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}
