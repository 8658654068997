import { Variants, VariantLabels } from 'framer-motion'

export const variant: { [key: string]: VariantLabels } = {
    initial: 'in',
    animate: 'animate',
    exit: 'exit'
}

export const animation: { [key: string]: Variants } = {
    global: {
        in: {
            x: '-5%',
            opacity: 0
        },
        animate: {
            x: '0%',
            opacity: 1
        },
        exit: {
            x: '-5%',
            opacity: 0
        }
    },
    imgColor: {
        in: {
            x: '-50%',
            opacity: 0
        },
        animate: {
            x: '0%',
            opacity: 1
        },
        exit: {
            x: '-50%',
            opacity: 0
        }
    },
    img: {
        in: {
            zIndex: 4,
            x: '-25%',
            opacity: 0
        },
        animate: {
            zIndex: 4,
            x: '0%',
            opacity: 1
        }
    }
}
