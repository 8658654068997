import React from 'react'

import { Container } from 'components/Container'
import { Text, TextProps } from 'components/Text'
import { Link } from 'components/Link'
import { ImageProps } from 'components/Image'

export interface TeaserBannerProps {
    blok: {
        headline: string
        subline: string
        linkTitle: string
        link: {
            url: string
            linktype: string
        }
        image: {
            filename: string
            alt: string
        }
    }
    headline: string
    subline: string
    img: ImageProps
}

export const TeaserBanner: React.FC<TeaserBannerProps> = ({ blok }) => {
    const { headline, subline, link, linkTitle, image } = blok
    const textStyles: { [key: string]: TextProps } = {
        headline: {
            tag: 'h3',
            content: headline,
            classes: 'h1 fnt-utl-clr--inverted',
            paddingTop: '2xl',
            paddingBottom: 'none'
        },
        subline: {
            tag: 'h4',
            classes: 'fnt-utl-fam--recia fnt-utl-clr--inverted fnt-utl-line-height--text',
            content: subline,
            paddingTop: 'l',
            paddingBottom: 'l'
        }
    }

    return (
        <div className="container-wrapper--bg-brand-four">
            <img src={image.filename} alt={image.alt} className="img" />
            <Container paddingTop={'none'} paddingBottom={'none'} isFluid={true}>
                <div className="row">
                    <div className="col-12">
                        <Text {...textStyles.headline} />
                    </div>
                    <div className="col-12 col-sm-8">
                        <Text {...textStyles.subline} />
                        <br />
                        <br />
                        <br /> 
                    </div>
                </div>
            </Container>
        </div>
    )
}
