import React, { useState } from 'react'
import { Link } from 'gatsby'
import { motion, AnimatePresence, Variants, Transition } from 'framer-motion'

import { Query as allPagesQuery } from 'components/Teasers/QueryTeaserFullImg'
import { Query, I_NavigationItem } from '../Staticquery'
import { Text } from 'components/Text'
import { I_NavItem } from '../index'

export interface MobileNavProps {
    language: string
    navItems?: I_NavItem[]
}

const variants: Variants = {
    init: {
        opacity: 0,
        y: '-100%'
    },
    animate: {
        opacity: 1,
        y: '0%'
    }
}

const navVariants: Variants = {
    init: {
        opacity: 0,
        y: '-20%'
    },
    animate: {
        opacity: 1,
        y: '0%'
    }
}

const trans: Transition = {
    duration: 0.5,
    ease: 'easeInOut'
}

export const MobileNav: React.FC<MobileNavProps> = ({ language, navItems }) => {
    const [isVisible, setIsVisible] = useState(false)
    const {
        dataPages: { allPages }
    } = Query()

    const {
        allStories: { stories }
    } = allPagesQuery()

    const renderSubNavigation = (items: I_NavItem[], title: string, index: number) => {
        const childLinks: any = []
        items.map(({ link }) => stories.find(({ story }) => story.uuid === link.id && childLinks.push(story)))

        return (
            <motion.li
                key={index}
                className={'list__item'}
                initial={'init'}
                animate={'animate'}
                exit={'exit'}
                variants={navVariants}
                transition={{ ...trans, delay: 0.12 * (index + 1.5) }}
            >
                <Text tag={'p'} classes={'small'} content={title} paddingTop={'l'} paddingBottom={'m'} />
                <ul className={`list gap-b-xl`}>
                    {childLinks.map((item: any, index: number) => (
                        <motion.li
                            key={index}
                            className="gap-t-m"
                            initial={'init'}
                            animate={'animate'}
                            exit={'exit'}
                            variants={navVariants}
                            transition={{ ...trans, delay: 0.12 * (index + 1.5) }}
                        >
                            <Link className="p" to={`/${item.full_slug}`} onClick={() => setIsVisible(false)}>
                                {item.name}
                            </Link>
                        </motion.li>
                    ))}
                </ul>
            </motion.li>
        )
    }

    const renderLink = (linkId: string) => {
        const hit = stories.find(({ story }) => story.uuid === linkId)
        return hit ? (
            <Link className="small fnt-utl-wei--600" to={`/${hit.story.full_slug}`} onClick={() => setIsVisible(false)}>
                <Text tag={'p'} classes={'h3'} content={hit.story.name} paddingTop={'none'} paddingBottom={'l'} />
            </Link>
        ) : null
    }

    const renderNavPoints = () => (
        <ul className={'list'}>
            {navItems?.map(
                ({ childs, link, title }, index) =>
                    childs.length === 0 ? (
                        <motion.li
                            key={index}
                            className={'list__item'}
                            initial={'init'}
                            animate={'animate'}
                            exit={'exit'}
                            variants={navVariants}
                            transition={{ ...trans, delay: 0.12 * (index + 1.5) }}
                        >
                            {renderLink(link.id)}
                        </motion.li>
                    ) : (
                        renderSubNavigation(childs, title, index)
                    )
                //item.children &&
            )}
        </ul>
    )

    return (
        <>
            <AnimatePresence>
                {isVisible && (
                    <motion.div
                        initial={'init'}
                        animate={'animate'}
                        exit={'init'}
                        variants={variants}
                        transition={trans}
                        className="mobile-nav__plane"
                    >
                        {renderNavPoints()}
                    </motion.div>
                )}
            </AnimatePresence>
            <button
                type={'button'}
                onClick={() => setIsVisible(!isVisible)}
                className={`mobile-nav__button ${isVisible ? 'mobile-nav__button--open' : ''}`}
            >
                <span />
                <span />
            </button>
        </>
    )
}
