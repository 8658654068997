import React, { useContext } from 'react'

import { sbClient, convertStoryContent } from 'hooks/useStoryblok'
import { ButtonRound } from 'components/Buttons'
import { I_ComponentSettings } from 'common/types/Component'
import { Container } from 'components/Container'
import { Image } from 'components/Image'
import { Illustrations, T_IlluType } from 'components/Features/Illustrations'
import { Text } from 'components/Text'
import { Modal } from 'components/Modal'
import { Link } from 'components/Link'
import { VisibilityWrapper } from 'components/VisibilityWrapper'

import { ModalContext } from 'context/Modal'
import { Query } from './QueryTeaserFullImg'
import { variantsImg, variantsClrWall, variantsClrWallReverse, transition } from './animations/TeaserTextImg'

export interface TeaserTextImgProps extends I_ComponentSettings {
    blok: {
        meta?: string
        metaIcon?: string
        headline: string
        subline: string
        description: any
        isReverse: boolean
        isInline: boolean
        imgBgColor: string
        linkTitle: string
        link: {
            id: string
        }
        image: {
            filename: string
            alt: string
        }
    }
    location: Location

    description: string
    headline: string
    img: {
        src: string
    }
    imgBgColor: string
    meta?: string
    metaIcon?: T_IlluType
    subline?: string
    language: string
    learnMore?: {
        isLink: boolean
        title: string
        slug: string
        content?: string
        contentLink?: string
    }
}

export const TeaserTextImg: React.FC<TeaserTextImgProps> = ({ blok, settings, location }) => {
    const { castModal, setContent } = useContext(ModalContext)
    const { meta, metaIcon, image, isReverse, linkTitle, isInline, headline, subline, description, imgBgColor } = blok
    const html = sbClient.richTextResolver.render(description)

    const ttiContentClasses = isReverse
        ? 'col-12 col-md-12 col-lg-6 col-xl-6 offset-xl-1 d-flex align-items-center'
        : 'col-12 col-md-12 col-lg-6 col-xl-7 d-flex align-items-center'

    const renderLink = (link: string, linkTitle: string) => {
        const {
            allStories: { stories }
        } = Query()

        const filteredStory = stories.find(({ story }) => story.uuid === blok.link.id)

        if (!filteredStory) return

        const convertedStory = convertStoryContent(filteredStory.story)

        return convertedStory.isPage ? (
            <Link slug={`${convertedStory.full_slug}`} withIcon={true} title={linkTitle} />
        ) : (
            <div className="gap-t-xl">
                <div
                    className="d-flex align-items-center"
                    onClick={() => {
                        castModal()
                        setContent(<Modal content={convertedStory.content || null} location={location} />)
                    }}
                >
                    <Text
                        tag="span"
                        classes="small fnt-utl-trn--uppercase fnt-utl-wei--500 gap-r-m"
                        content={`${linkTitle}`}
                        paddingTop={'none'}
                        paddingBottom={'none'}
                    />
                    <ButtonRound title={'+'} delegateClick={() => {}} />
                </div>
            </div>
        )
    }

    const ttiContent: React.ReactNode = (
        <div key={1} className={ttiContentClasses}>
            <div className="teaser-text-img__content">
                {meta ? (
                    <VisibilityWrapper variants={variantsImg} transition={{ ...transition, delay: 0.25 }}>
                        <div className="d-flex align-items-center">
                            {metaIcon ? <Illustrations classes="teaser-text-img__icon" type={metaIcon} /> : ''}
                            <Text
                                tag={'span'}
                                classes="small fnt-utl-trn--uppercase fnt-utl-wei--500"
                                content={meta}
                                paddingTop={'none'}
                                paddingBottom={'none'}
                            />
                        </div>
                    </VisibilityWrapper>
                ) : (
                    ''
                )}
                {headline ? (
                    <VisibilityWrapper variants={variantsImg} transition={{ ...transition, delay: 0.25 }}>
                        <Text tag={'h3'} classes="h3 fnt-utl-wei--500" content={headline} paddingTop={'m'} paddingBottom={'l'} />
                    </VisibilityWrapper>
                ) : (
                    ''
                )}
                {subline ? (
                    <VisibilityWrapper variants={variantsImg} transition={{ ...transition, delay: 0.25 }}>
                        <Text tag={'p'} classes="h4 fnt-utl-fam--recia" content={subline} paddingTop={'none'} paddingBottom={'l'} />
                    </VisibilityWrapper>
                ) : (
                    ''
                )}
                {description && (
                    <VisibilityWrapper variants={variantsImg} transition={{ ...transition, delay: 0.25 }}>
                        <div className="content fnt-utl-wei--400 fnt-utl-line-height--text" dangerouslySetInnerHTML={{ __html: html }} />
                    </VisibilityWrapper>
                )}
                {blok.link && (
                    <VisibilityWrapper variants={variantsImg} transition={{ ...transition, delay: 0.25 }}>
                        {renderLink(blok.link as any, linkTitle)}
                    </VisibilityWrapper>
                )}
            </div>
        </div>
    )

    const colorWallClasses = isReverse
        ? `teaser-text-img__column--is-inline teaser-text-img__column--is-inline-${imgBgColor}`
        : `teaser-text-img__column--is-inline-reverse`

    const ttiImg: React.ReactNode = (
        <div
            key={2}
            className={`col-12 col-md-12 col-lg-6 col-xl-5 teaser-text-img__column ${
                isInline ? colorWallClasses : ''
            } teaser-text-img__column--is-inline-${imgBgColor}`}
        >
            <VisibilityWrapper styles={{ position: 'relative', height: '100%', zIndex: 3 }} variants={variantsImg} transition={transition}>
                <img src={image.filename} alt={Image.alt} />
            </VisibilityWrapper>
            <VisibilityWrapper
                styles={{ position: 'absolute', height: '100%', zIndex: 1, top: 0, left: 0, width: '100%' }}
                variants={isReverse ? variantsClrWallReverse : variantsClrWall}
                transition={{ ...transition, delay: 1.25 }}
            >
                <div className="teaser-text-img__clr-wall"></div>
            </VisibilityWrapper>
        </div>
    )

    const ttiInner: React.ReactNode[] = [ttiContent, ttiImg]
    isReverse && ttiInner.reverse()

    const renderColorWall = () => (
        <div
            className={`teaser-text-img__img-bg-color teaser-text-img__img-bg-color--${imgBgColor} ${
                settings?.isReverse ? 'teaser-text-img__img-bg-color--reverse' : ''
            }`}
        />
    )

    return (
        <div className={`teaser-text-img ${settings?.classes ? settings.classes : ''}`}>
            {!isInline && renderColorWall()}
            <Container isFluid={true} paddingTop={'3xl'} paddingBottom={'3xl'}>
                <div className={`row ${isReverse ? 'flex-column-reverse flex-lg-row' : ''}`}>{ttiInner.map((item) => item)}</div>
            </Container>
        </div>
    )
}
