import React from 'react'
import { Illustrations } from 'components/Features/Illustrations'

export interface BlockquoteProps {
    blok: {
        quote: string
        cite: string
    }
}

export const Blockquote: React.FC<BlockquoteProps> = ({ blok: { quote, cite } }) => {
    return (
        <div className="container gap-t-2xl gap-b-2xl">
            <div className="row">
                <div className="col-12">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto 32px',
                            width: 64,
                            height: 64,
                            borderRadius: '50%',
                            border: '1px solid #0566ff',
                            fontSize: '64px',
                            lineHeight: 0
                        }}
                        className="text-center"
                    >
                        <Illustrations classes="blockquote__icon" type={'cite'} />
                    </div>
                    <blockquote style={{ margin: 0 }} className="text-center">
                        <p className="fnt-utl-fam--recia fnt-utl-clr--brand-one fnt-utl-line-height--text gap-b-l">{quote}</p>
                        <cite className="display-inline-block">{cite}</cite>
                    </blockquote>
                </div>
            </div>
        </div>
    )
}
