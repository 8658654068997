import React from 'react'

import { sbClient } from 'hooks/useStoryblok'
import { T_Padding } from 'common/types/Component'
import { Container } from 'components/Container'

export interface ContentProps {
    blok: {
        content: any
        isSerif: boolean
        isLongtext: boolean
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const Content: React.FC<ContentProps> = ({ blok }) => {
    const { content, paddingTop, paddingBottom, isSerif, isLongtext } = blok
    const html = sbClient.richTextResolver.render(content)

    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom} isFluid={true}>
            <div className="row">
                <div className="col-12">
                    <div
                        className={`text content ${isSerif ? 'fnt-utl--legible fnt-utl-fam--recia' : ''} ${isLongtext ? 'longtext' : ''}`}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </div>
        </Container>
    )
}
