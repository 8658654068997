import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useWindowSize } from 'hooks/useWindow'
import { BREAKPOINS } from 'common/constants/Breakpoints'

export interface I_TableItem {
    title: string
    subline: string
    tHead: {
        value: string[]
    }
    values: [string, number]
}

export interface TableProps {
    tHead: {
        value: string[]
    }[]
    tBody: {
        body: {
            value: string[]
        }[]
    }[]
}

export const Table: React.FC<TableProps> = ({ tHead, tBody }) => {
    const refTable = useRef<HTMLTableElement>(null)
    const [tableWidth, setTableWidth] = useState<number | null>(null)
    const { width: windowW } = useWindowSize()
    const [windowWidth, setWindowWidth] = useState<number>(0)
    const [shouldDrag, setShouldDrag] = useState<boolean>(false)
    let tableHeader: any[] = []

    tHead.map((item, index) => {
        tableHeader.push(`<strong>${item.value}</strong>`)
    })

    useEffect(() => {
        windowW !== undefined && windowW < 580 ? setShouldDrag(true) : setShouldDrag(false)
    }, [windowW])

    useEffect(() => {
        windowW !== undefined && setWindowWidth(windowW || 0)
    }, [windowW])

    useEffect(() => {
        setTimeout(() => {
            if (refTable.current) {
                const { width } = refTable.current.getBoundingClientRect()
                setTableWidth(-(width - windowWidth + 100))
            }
        }, 500)
    }, [refTable, windowWidth])

    const renderTable = () => (
        <table ref={refTable} className="table">
            <thead>
                <th className="table__thead">
                    {tableHeader.map((item) => (
                        <tr className="table__trow">
                            <span dangerouslySetInnerHTML={{ __html: `${item}` }}></span>
                        </tr>
                    ))}
                </th>
            </thead>
            <tbody className="table__body">
                {tBody.map(({ body }) => (
                    <tr className="table__column">
                        {body.map(({ value }) => (
                            <td className="gap-t-s">{value}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )

    return (
        <div style={{ maxWidth: '100vw' }}>
            {shouldDrag ? (
                <motion.div drag={'x'} dragConstraints={{ left: tableWidth || 0, right: 0 }}>
                    {renderTable()}
                </motion.div>
            ) : (
                renderTable()
            )}
        </div>
    )
}
