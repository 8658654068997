import React from 'react'
import { sbClient } from 'hooks/useStoryblok'

import { Container } from 'components/Container'
import { Text, TextProps } from 'components/Text'
import { Illustrations, T_IlluType } from 'components/Features/Illustrations'

export interface I_BulletItem {
    key: string
    icon?: T_IlluType
    value: string
    description: string
}

export interface BulletTextProps {
    blok: {
        items: I_BulletItem[]
    }
}

export const BulletText: React.FC<BulletTextProps> = ({ blok }) => {
    const { items } = blok

    const renderItem = (item: I_BulletItem, index: number) => {
        const html = sbClient.richTextResolver.render(item.description as any)

        const text: { [key: string]: TextProps } = {
            value: {
                tag: 'p',
                classes: 'body d-flex align-items-center fnt-utl-clr--brand-one fnt-utl-trn--uppercase',
                content: item.value,
                paddingTop: 'none',
                paddingBottom: 'none'
            },
            description: {
                tag: 'p',
                classes: 'body fnt-utl-line-height--text d-flex align-items-center',
                content: item.description,
                paddingTop: 'none',
                paddingBottom: 'none'
            }
        }

        return (
            <div key={index} className="col-12 gap-t-xl">
                <div className="row gy-3">
                    <div className="col-12 d-flex justify-content-center">
                        <div className="key-stat key-stat--bullet key-stat--max-width key-stat--height-auto">
                            <div className="key-stat__body">
                                {item.icon ? (
                                    <Illustrations classes="key-stat__icon key-stat__icon--big" type={item.icon} />
                                ) : (
                                    <p className="h2 d-flex align-items-center fnt-utl-clr--brand-one">{item.key}</p>
                                )}
                            </div>
                            <div className="key-stat__footer">
                                <Text {...text.value} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-flex align-item-center">
                        {item.description && <div className="long-text" dangerouslySetInnerHTML={{ __html: html }} />}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Container paddingTop={'none'} paddingBottom={'3xl'} isFluid={false}>
            <div className="row">{items.map((item, index) => renderItem(item, index))}</div>
        </Container>
    )
}
