import { graphql, useStaticQuery } from 'gatsby'

export interface I_NavigationItem {
    title: string
    slug: string
    children?: string[]
}

export interface I_Navigation {
    navigation: {
        items: I_NavigationItem[]
    }
}

export interface I_Global {
    data: {
        allGlobal: {
            global: {
                language: string
                navigation: {
                    items: I_NavigationItem[]
                }
            }
        }[]
    }
    dataPages: {
        allPages: {
            page: {
                id: string
                title: string
                slug: string
            }
        }[]
    }
}

export function Query(): I_Global {
    return useStaticQuery(graphql`
        query Navigation {
            data: allGlobalJson {
                allGlobal: edges {
                    global: node {
                        language
                        navigation {
                            items {
                                title
                                slug
                                children
                            }
                        }
                    }
                }
            }
            dataPages: allPagesJson {
                allPages: edges {
                    page: node {
                        id
                        title
                        slug
                    }
                }
            }
        }
    `)
}
