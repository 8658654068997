import { useStaticQuery, graphql } from 'gatsby'

export interface I_Story {
    story: {
        isPage: boolean
        uuid: string
        full_slug: string
        name: string
        content: any
    }
}

export interface I_TeaserFullPageQuery {
    allStories: {
        stories: I_Story[]
    }
    data: any
}

export function Query(): I_TeaserFullPageQuery {
    return useStaticQuery(graphql`
        query TeaserFullPage {
            allStories: allStoryblokEntry {
                stories: edges {
                    story: node {
                        isPage: field_isPage_boolean
                        uuid
                        full_slug
                        name
                        content
                    }
                }
            }
            data: allPagesJson {
                pages: edges {
                    page: node {
                        id
                        imgBgColor
                        title
                        description
                        slug
                        content {
                            type
                            meta
                            headline
                            subline
                            content
                            description
                            imgBgColor
                            bulletItems {
                                key
                                icon
                                value
                                description
                            }
                            img {
                                src
                            }
                            downloadItems {
                                icon
                                title
                                link
                            }
                        }
                    }
                }
            }
        }
    `)
}
