import React from 'react'
import { motion } from 'framer-motion'

import { Query } from 'components/Teasers/QueryTeaserFullImg'
import { Footer } from 'components/Footer'

export interface MainLayoutProps {
    children: React.ReactNode
    location: any
    siblingId: string
    language: string
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children, location, language, siblingId }) => {
    const {
        allStories: { stories }
    } = Query()
    const hit = stories.find(({ story }) => story.uuid === siblingId)

    return (
        <motion.div
            key={location}
            initial={{
                y: '0%',
                opacity: 0
            }}
            animate={{ y: '0%', opacity: 1 }}
            exit={{
                opacity: 0,
                y: '-1%',
                transition: {
                    duration: 0.6,
                    delay: 0
                }
            }}
            transition={{
                ease: 'easeInOut',
                duration: 0.75,
                delay: 0.15,
                when: 'beforeChildren'
            }}
        >
            {children}
            <Footer location={location} language={language} siblingLink={hit?.story.full_slug} />
        </motion.div>
    )
}
