import React from 'react'
import { DynamicComponent } from 'components/DynamicComponent'
import SbEditable from 'storyblok-react'

export interface PageProps {
    blok: any
    location: Location
    language: string
}

export const Page: React.FC<PageProps> = ({ blok, location, language }) => {
    const content =
        blok.content &&
        blok.content.map((childBlok: any) => (
            <DynamicComponent blok={childBlok} key={childBlok._uid} location={location} language={language} />
        ))
    return (
        <SbEditable content={blok} key={blok._uid}>
            {content}
        </SbEditable>
    )
}
