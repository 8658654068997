import { Variants, Transition } from 'framer-motion'

export const variantsImg: Variants = {
    hidden: {
        opacity: 0,
        y: '50%'
    },
    visible: {
        opacity: 1,
        y: '0%'
    }
}

export const variantsClrWall: Variants = {
    hidden: {
        opacity: 0,
        x: '25%'
    },
    visible: {
        opacity: 1,
        x: '0%'
    }
}

export const variantsClrWallReverse: Variants = {
    hidden: {
        opacity: 0,
        x: '-25%'
    },
    visible: {
        opacity: 1,
        x: '0%'
    }
}

export const transition: Transition = {
    ease: 'easeInOut',
    duration: 0.75
}
