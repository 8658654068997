import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence, useMotionValue, useTransform } from 'framer-motion'
import { wrap } from 'popmotion'
import { HeroSliderPanel } from './Elements/HeroSliderPanel'
import { variants } from './AnimationVariants'
import { Query } from 'components/Teasers/QueryTeaserFullImg'

export interface I_SlideItem {
    meta: string
    headline: string
    slug?: string
    link: {
        id: string
        cached_url: string
    }
    image: {
        filename: string
        alt: string
    }
    img: {
        src: string
    }
}

export interface HeroSliderProps {
    blok: {
        items: I_SlideItem[]
    }
    location: Location
    slideItems: I_SlideItem[]
}

const swipeConfidenceThreshold = 100000
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
}

export const HeroSlider: React.FC<HeroSliderProps> = ({ blok, location, slideItems }) => {
    const { items } = blok

    const [page, setPage] = useState(0)
    const x = useMotionValue(0)
    const xInput = [-300, 0, 300]
    const slideIndex = wrap(0, items.length, page)
    const imageScale = useTransform(x, xInput, [1.15, 1, 1.15])

    const [timer, setTimer] = useState(0)
    const [isActive, setIsActive] = useState(false)
    const [isPaused, setIsPaused] = useState(false)
    const countRef = useRef<any | null>(null)

    const handleStart = () => {
        setIsActive(true)
        setIsPaused(true)
        countRef.current = setInterval(() => {
            setTimer((timer) => timer + 1)
        }, 1000)
        // start button logic here
    }

    const handlePause = () => {
        clearInterval(countRef.current)
        setIsPaused(false)
        // Pause button logic here
    }

    const handleResume = () => {
        setIsPaused(true)
        countRef.current = setInterval(() => {
            setTimer((timer) => timer + 1)
        }, 1000)
        // Resume button logic here
    }

    const handleReset = () => {
        // Reset button logic here
        clearInterval(countRef.current)
        setIsActive(false)
        setIsPaused(false)
        setTimer(0)
    }

    useEffect(() => {
        handleStart()
    }, [])

    useEffect(() => {
        if (timer > 8) {
            handleReset()
            handleStart()
            page < items.length - 1 ? setPage(page + 1) : setPage(0)
        }
    }, [timer])

    const paginate = (newDirection: number) => {
        setPage(page + newDirection)
        handleReset()
        handleStart()
    }

    const renderDragFrame = () => (
        <motion.div
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            style={{ x }}
            dragElastic={1}
            className="hero-slider__frame-wrapper"
            onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) {
                    paginate(1)
                } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1)
                }
            }}
        ></motion.div>
    )

    const renderImg = (slideItem: I_SlideItem, index: number) => (
        <div className="hero-slider__frame__inner">
            <motion.div
                style={{ scale: imageScale, height: 'inherit' }}
                variants={variants}
                initial="inOut"
                animate="entered"
                exit="inOut"
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                <img
                    className="hero-slider__img"
                    src={slideItem.image.filename}
                    alt={slideItem.image.alt}
                    loading={index === 0 ? 'eager' : 'lazy'}
                />
            </motion.div>
        </div>
    )

    const renderSlideItem = (slideItem: I_SlideItem, index: number) => {
        const {
            allStories: { stories }
        } = Query()
        const hit = stories.find(({ story }) => story.uuid === slideItem.link.id)

        if (!hit) return

        return (
            <AnimatePresence initial={true} key={index}>
                {slideIndex === index && (
                    <>
                        {renderDragFrame()}
                        {renderImg(slideItem, index)}
                        <HeroSliderPanel
                            x={x}
                            xInput={xInput}
                            meta={slideItem.meta}
                            headline={slideItem.headline}
                            slug={hit.story.full_slug}
                            isPaused={isPaused}
                            delegateMouseOver={() => handlePause()}
                            delegateMouseLeave={() => handleResume()}
                            delegateClick={(number) => paginate(number)}
                        />
                    </>
                )}
            </AnimatePresence>
        )
    }

    return (
        <div className="hero-slider">
            <div className="hero-slider__frame">
                {items && items.map((item, index) => slideIndex === index && renderSlideItem(item, index))}
            </div>
        </div>
    )
}
