import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { useWindowSize } from 'hooks/useWindow'
import { convertStoryContent } from 'hooks/useStoryblok'
import { Container } from 'components/Container'
import { Divider } from 'components/Divider'
import { Image, ImageProps } from 'components/Image'
import { Link } from 'components/Link'
import { Text, TextProps } from 'components/Text'
import { animation, variant } from './animations/TeaserProducts'

import { Query } from 'components/Teasers/QueryTeaserFullImg'

export interface TeaserProductsProps {
    settings: {
        linkAsModal: boolean
        inlineContent?: boolean
    }
    blok: {
        headline: string
        links: {
            link: {
                id: string
            }
        }[]
    }
    language: string
    location: Location
    headline: string
    products: string[]
}

export const TeaserProducts: React.FC<TeaserProductsProps> = ({ language, blok }) => {
    const { headline, links } = blok
    const [activeProduct, setActiveProduct] = useState(links[0].link.id)
    const { width } = useWindowSize()
    const {
        allStories: { stories }
    } = Query()

    const filterProducts: any = []

    links.map(({ link }) =>
        stories.find(({ story }) => {
            const hit = story.uuid === link.id && story
            if (hit) {
                const convertedPage = convertStoryContent(hit)
                filterProducts.push(convertedPage)
            }
        })
    )

    const renderTab = (item: any) => (
        <div
            key={item.id}
            className={`tabs__item ${activeProduct === item.uuid ? 'tabs__item--is-active' : ''}`}
            onClick={() => setActiveProduct(() => item.uuid)}
        >
            <Text
                tag={'h3'}
                classes="small fnt-utl-wei--500 fnt-utl-trn--uppercase fnt-utl-al--center"
                content={item.content.content[0].meta}
                paddingTop={'none'}
                paddingBottom={'m'}
            />
        </div>
    )

    const renderImgColumn = (img: { filename: string; alt: string }, imgBgColor: string) => {
        const columnClasses = `teaser-products__img-wrapper teaser-text-img__column--is-inline-${imgBgColor}`
        return (
            <motion.div
                className={`${columnClasses}`}
                variants={animation.img}
                transition={{ duration: 0.5, delay: 0.25, ease: 'easeInOut' }}
                {...variant}
            >
                <img className="teaser-products__img" src={img.filename} alt={img.alt} />
            </motion.div>
        )
    }

    const animTextWrapper = (item: React.ReactNode, delayMultiple: number) => (
        <motion.div
            variants={animation.imgColor}
            transition={{ duration: 0.5, delay: 0.25 * delayMultiple, ease: 'easeInOut' }}
            {...variant}
        >
            {item}
        </motion.div>
    )

    const renderContentColumn = (meta: TextProps, headline: TextProps, subline: TextProps, slug: string | undefined, img: ImageProps) => {
        const columnClasses = 'col-12'
        return (
            <div className="container-fluid d-flex align-items-center">
                <div className="row flex-column-reverse flex-lg-row">
                    <div className={columnClasses}>
                        <div className="teaser-products__content">
                            {meta ? animTextWrapper(<Text {...meta} />, 0.2) : ''}
                            {animTextWrapper(<Divider />, 0.2)}
                            {headline ? animTextWrapper(<Text {...headline} />, 0.4) : ''}
                            {width && width > 577 && subline ? animTextWrapper(<Text {...subline} />, 0.6) : ''}
                            {width && width < 577 && img
                                ? animTextWrapper(
                                      <div className="gap-t-xl gap-b-xl">
                                          <Image {...img} />
                                      </div>,
                                      0.6
                                  )
                                : ''}
                            {animTextWrapper(
                                <Link slug={`${slug}`} title={language === 'en' ? `Learn More` : `Erfahren Sie mehr`} withIcon={true} />,
                                0.6
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderProduct = (item: any) => {
        const content: { [key: string]: TextProps } = {
            headline: {
                content: item.content.content[0].headline,
                tag: 'p',
                classes: 'h3 fnt-utl-wei--500',
                paddingTop: 'l',
                paddingBottom: 'none'
            },
            subline: {
                content: item.content.Seo.description,
                tag: 'p',
                classes: 'body fnt-utl-line-height--text',
                paddingTop: 'm',
                paddingBottom: 'm'
            },
            meta: {
                content: item.content.content[0].meta,
                tag: 'p',
                classes: 'body fnt-utl-wei--700 fnt-utl-trn--uppercase',
                paddingTop: 'm',
                paddingBottom: 'm'
            }
        }

        return (
            activeProduct === item.uuid && (
                <motion.div
                    key={`${item.uuid}`}
                    className="teaser-products__stage"
                    variants={animation.global}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    {...variant}
                >
                    {width && width >= 577 && item.content.content[0].image
                        ? renderImgColumn(item.content.content[0].image, item.content.content[0].imgBgColor)
                        : ''}
                    {renderContentColumn(content.meta, content.headline, content.subline, item.full_slug, item.content.content[0].image)}
                </motion.div>
            )
        )
    }

    return (
        <div className="teaser-products">
            <Container paddingTop={'none'} paddingBottom={'none'} isFluid={true}>
                <Text tag={'h2'} classes={'h2'} content={headline} paddingTop={'3xl'} paddingBottom={'xl'} />
                <div className="tabs">{filterProducts.map((item: any) => renderTab(item))}</div>
            </Container>
            <Divider />
            <AnimatePresence exitBeforeEnter>{filterProducts.map((item: any) => renderProduct(item))}</AnimatePresence>
        </div>
    )
}
