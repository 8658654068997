import React from 'react'
import { Link as GLink } from 'gatsby'
import { ButtonRound } from 'components/Buttons'

export interface LinkProps {
    classes?: string
    isExternal?: boolean
    slug: string
    title: string
    withIcon: boolean
}

export const Link: React.FC<LinkProps> = ({ classes, slug, title, isExternal, withIcon }) => {
    const classesInternal = 'link small d-flex align-items-center fnt-utl-wei--600 fnt-utl-trn--uppercase gap-t-l'
    const renderIcon = () => <ButtonRound title={'→'} delegateClick={() => {}} />

    return isExternal === undefined || !isExternal ? (
        <GLink className={`${classesInternal} ${classes ? classes : ''}`} to={`/${slug}`}>
            <span className={`gap-r-m ${classes ? classes : ''}`}>{title}</span>
            {withIcon && renderIcon()}
        </GLink>
    ) : (
        <a className={`${classesInternal} ${classes ? classes : ''}`} href={`https://${slug}`}>
            <span className={`gap-r-m ${classes ? classes : ''}`}>{title}</span>
            {withIcon && renderIcon()}
        </a>
    )
}
