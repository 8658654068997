import React, { CSSProperties } from 'react'
import { motion, useViewportScroll, useTransform, MotionStyle } from 'framer-motion'

import { Container } from 'components/Container'
import { Divider } from 'components/Divider'
import { Image } from 'components/Image'
import { Text, TextProps } from 'components/Text'

export interface HeroProps {
    blok: {
        description: string
        headline: string
        meta: string
        scrollAnimation: boolean
        descriptionOutside: boolean
        image: {
            filename: string
            alt: string
        }
    }
    settings: {
        descriptionOutside: boolean
        scrollAnimation?: boolean
    }
    meta: string
    headline: string
    description: string
    image: {
        filename: string
        alt: string
    }
    img: {
        src: string
        alt: string
    }
}

const inputRange: number[] = [0, 0.1]

export const Hero: React.FC<HeroProps> = ({ blok }) => {
    const { headline, description, meta, image, scrollAnimation, descriptionOutside } = blok
    const { scrollYProgress } = useViewportScroll()

    const y = useTransform(scrollYProgress, inputRange, ['0%', '40%'])
    const opacity = useTransform(scrollYProgress, inputRange, [1, 0])

    const animationWrapper = (child: React.ReactNode, delay: number, style?: CSSProperties) => (
        <div style={style || undefined}>
            <motion.div
                initial={{ y: '20%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                exit={{ y: '20%', opacity: 0 }}
                transition={{ duration: 0.8, delay: 0.25 * delay, ease: 'easeInOut' }}
            >
                {child}
            </motion.div>
        </div>
    )

    const renderText = (headline: TextProps) => <Text {...headline} />

    const renderDescription = () => {
        if (!descriptionOutside) {
            return (
                <Container paddingTop={'none'} paddingBottom={'none'} isFluid={true}>
                    <div className="row">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                            <div className="hero__panel">
                                <div className="h4 fnt-utl-line-height--text" dangerouslySetInnerHTML={{ __html: description }}></div>
                            </div>
                        </div>
                    </div>
                </Container>
            )
        } else {
            return (
                <Container paddingTop={'3xl'} paddingBottom={'none'} isFluid={true}>
                    <div className="row">
                        <div className="col-12 ">
                            <div
                                className="h3 fnt-utl-al--center fnt-utl-line-height--text"
                                dangerouslySetInnerHTML={{ __html: description }}
                            ></div>
                        </div>
                    </div>
                </Container>
            )
        }
    }

    const renderHeader = () => (
        <motion.header className="hero__header" style={scrollAnimation || scrollAnimation === undefined ? { y, opacity } : {}}>
            <Container paddingTop={'3xl'} paddingBottom={'xl'} isFluid={true}>
                {renderText({
                    tag: 'h2',
                    classes: 'body fnt-utl-wei--500 fnt-utl-trn--uppercase',
                    content: meta,
                    paddingTop: 'none',
                    paddingBottom: 'm'
                })}
                {animationWrapper(<Divider />, 0.4)}
                {animationWrapper(renderText({ tag: 'h3', classes: 'h2', content: headline, paddingTop: 'm', paddingBottom: 'none' }), 0.6)}
            </Container>
        </motion.header>
    )

    return (
        <div className="hero">
            {renderHeader()}
            {image.filename !== ''!
                ? animationWrapper(
                      <motion.div className="hero__img-frame">
                          <motion.div className="hero__img">
                              <img src={image.filename} alt={image.alt} loading={'eager'} />
                          </motion.div>
                      </motion.div>,
                      0.2,
                      { position: 'relative', zIndex: 10 }
                  )
                : ''}
            {description && renderDescription()}
        </div>
    )
}
