import { Variants, VariantLabels, Transition } from 'framer-motion'

export const variant: { [key: string]: VariantLabels } = {
    initial: 'in',
    animate: 'animate',
    exit: 'exit'
}

export const animations: { [key: string]: Variants } = {
    pane: {
        in: {
            opacity: 0,
            y: '10%'
        },
        animate: {
            opacity: 1,
            y: '0%'
        },
        exit: {
            opacity: 0,
            y: '10%'
        }
    },
    item: {
        in: {
            opacity: 0,
            y: '50%'
        },
        animate: {
            opacity: 1,
            y: '0%'
        },
        exit: {
            opacity: 0,
            y: '50%'
        }
    }
}

export const transition: Transition = { ease: 'easeInOut', delay: 0.05 }
