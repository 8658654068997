import React, { useMemo } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { queryImages } from './StaticQuery'

export interface ImageProps {
    src: string
    alt?: string
    classes?: string
    loading?: 'eager' | 'lazy'
}

export const Image: React.FC<ImageProps> = ({ src, classes, alt, loading }) => {
    // const data = queryImages()
    // const match = useMemo(() => data.images.edges.find(({ node }) => src === node.relativePath), [data, src])

    // if (!match) return null
    // const { node } = match
    // const image: any = getImage(node.childImageSharp.gatsbyImageData)

    // return <GatsbyImage className={classes} image={image} alt={alt || 'Cordenka Image'} loading={loading || 'lazy'} />
    return <img src="" />
}
