import React from 'react'

import { sbClient } from 'hooks/useStoryblok'

export interface I_TimelineItem {
    year: number
    description: RichText
}

export interface TimelineProps {
    blok: {
        items: I_TimelineItem[]
    }
}

export const Timeline: React.FC<TimelineProps> = ({ blok }) => {
    const { items } = blok

    const renderTimelineItem = ({ year, description }: I_TimelineItem, index: number) => {
        const html = sbClient.richTextResolver.render(description)

        return (
            <div key={index} className="timeline__item row">
                <div className="timeline__year h1 col-12 col-sm-6 fnt-utl-clr--brand-one">
                    <div className="timeline__year-inner">{year}</div>
                </div>
                <div className="timeline__description text col-12 col-sm-6" dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
        )
    }

    return (
        <div className="timeline gap-t-l gap-b-2xl">
            <div className="container">
                <div className="row">
                    <div className="col-12">{items.map((timelineItem, index) => renderTimelineItem(timelineItem, index))}</div>
                </div>
            </div>
        </div>
    )
}
