import React from 'react'
import { Link } from 'gatsby'

import { convertStoryContent } from 'hooks/useStoryblok'
import { Container } from 'components/Container'
import { Divider } from 'components/Divider'
import { Text, TextProps } from 'components/Text'
import { Query } from 'components/Teasers/QueryTeaserFullImg'

export interface I_JobItem {
    title: string
    slug?: string
}

export interface JobsProps {
    blok: {
        items: {
            link: { id: string; url: '' }
            title?: string
        }[]
    }
    location: Location
}

export const Jobs: React.FC<JobsProps> = ({ blok }) => {
    const { items } = blok
    const {
        allStories: { stories }
    } = Query()

    return (
        <Container paddingTop={'l'} paddingBottom={'l'} isFluid={true}>
            {items.map(({ link, title }, index) => {
                const hit = stories.find(({ story }) => story.uuid === link.id)
                //if (!hit || (!title && link.url === '')) return
                console.log(link, title)
                if (link.url === '') {
                    const convertedPage = convertStoryContent(hit.story)
                    const content: { [key: string]: TextProps } = {
                        headline: {
                            tag: 'h4',
                            content: `${convertedPage.content.Seo.title}`,
                            paddingTop: 'm',
                            paddingBottom: 'm'
                        }
                    }
                    return (
                        <div key={index}>
                            <Link to={`/${convertedPage.full_slug}`}>
                                <Text {...content.headline} />
                            </Link>
                            <Divider />
                        </div>
                    )
                } else if (title !== undefined) {
                    const content: { [key: string]: TextProps } = {
                        headline: {
                            tag: 'h4',
                            content: `${title}`,
                            paddingTop: 'm',
                            paddingBottom: 'm'
                        }
                    }
                    return (
                        <div key={index}>
                            <a target="_blank" href={`${link.url}`}>
                                <Text {...content.headline} />
                            </a>
                            <Divider />
                        </div>
                    )
                }
            })}
        </Container>
    )
}
