import React from 'react'
import { Container } from 'components/Container'
import { Divider } from 'components/Divider'
import { Illustrations, T_IlluType } from 'components/Features/Illustrations'
import { Text, TextProps } from 'components/Text'

export interface I_DownloadItem {
    icon: T_IlluType
    title: string
    link: {
        filename: string
        id: number
        name: string
    }
    pageLink: {
        url: string
    }
}

export interface DownloadProps {
    blok: {
        headline: string
        items: I_DownloadItem[]
    }
}

export const Download: React.FC<DownloadProps> = ({ blok }) => {
    const { headline, items } = blok

    const textStyles: { [key: string]: TextProps } = {
        headline: {
            tag: 'h3',
            classes: 'body fnt-utl-wei--600',
            content: headline,
            paddingTop: 'l',
            paddingBottom: 's'
        }
    }

    const renderListItem = (item: I_DownloadItem, index: number) => (
        <li key={index} className="download list__item gap-t-m gap-b-s gap-l-m gap-r-m">
            <div className="download__content">
                <a
                    className="body d-block gap-t-s gap-b-m d-flex alig-items-center"
                    href={`${item.pageLink && item.pageLink.url !== '' ? `${item.pageLink.url}` : item.link.filename}`}
                    target={`_blank`}
                >
                    <Illustrations classes="download__icon" type={item.icon} />
                    <span className="d-block gap-l-m">{item.title}</span>
                </a>
            </div>
        </li>
    )

    return (
        <Container paddingTop={'none'} paddingBottom={'2xl'} isFluid={true}>
            <div className="row">
                <div className="col-12">
                    <Text {...textStyles.headline} />
                    <ul className="list gap-t-m">{items.map((item, index) => renderListItem(item, index))}</ul>
                </div>
            </div>
        </Container>
    )
}
