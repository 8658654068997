import React from 'react'

import { MainHeader } from 'components/MainHeader'

export interface FoundationLayoutProps {
    children: React.ReactNode
    language: string
    location: Location
    isMenuInverted: boolean
    siblingId: string
}

export const FoundationLayout: React.FC<FoundationLayoutProps> = ({ children, language, location, isMenuInverted, siblingId }) => {
    return (
        <>
            <MainHeader language={language} location={location} isMenuInverted={isMenuInverted} siblingId={siblingId} />
            {children}
        </>
    )
}
