import React from 'react'

import { sbClient } from 'hooks/useStoryblok'
import { Text, TextProps } from 'components/Text'
import { Container } from 'components/Container'
import { Illustrations, T_IlluType } from 'components/Features/Illustrations'

export interface I_KeyStat {
    isContentHeavy: boolean
    icon?: T_IlluType
    value: any
    title: string
}

export interface KeyStatsProps {
    blok: {
        items: I_KeyStat[]
    }
}

export const KeyStats: React.FC<KeyStatsProps> = ({ blok }) => {
    const { items } = blok

    const renderStat = (item: I_KeyStat, index: number) => {
        const classes = item.isContentHeavy ? 'col-12 col-sm-6 gy-3' : 'col-12 col-sm-6 col-lg-3 gy-3'
        const html = sbClient.richTextResolver.render(item.value)
        const content: { [key: string]: TextProps } = {
            value: {
                tag: 'p',
                classes: item.isContentHeavy ? 'key-stat__body text' : 'key-stat__body h2 fnt-utl-clr--brand-one',
                content: `${(<div dangerouslySetInnerHTML={{ __html: html }}></div>)}`,
                paddingTop: 'none',
                paddingBottom: 'none'
            },
            title: {
                tag: 'h4',
                classes: 'key-stat__footer body fnt-utl-trn--uppercase fnt-utl-clr--brand-one',
                content: item.title,
                paddingTop: 'none',
                paddingBottom: 'none'
            },
            titleBig: {
                tag: 'h4',
                classes: 'key-stat__footer text fnt-utl-trn--uppercase fnt-utl-clr--brand-one',
                content: item.title,
                paddingTop: 'none',
                paddingBottom: 'none'
            }
        }
        return (
            <div className={`${classes}`} key={index}>
                {item.icon ? (
                    <div className={`key-stat`}>
                        <Illustrations
                            classes="key-stat__body key-stat__icon fnt-utl-trn--uppercase fnt-utl-clr--brand-one"
                            type={item.icon}
                        />
                        <Text {...content.title} />
                    </div>
                ) : (
                    <div className={`key-stat ${item.value ? '' : 'key-stat--reverse'}`}>
                        {item.value ? (
                            <>
                                <div
                                    className={`${content.value.classes} gap-t-${content.value.paddingTop} gap-b-${content.value.paddingTop}`}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                                <Text {...content.title} />
                            </>
                        ) : (
                            <Text {...content.titleBig} />
                        )}
                    </div>
                )}
            </div>
        )
    }
    return (
        <Container paddingTop={'2xl'} paddingBottom={'2xl'} isFluid={false}>
            <div className="row">{items.map((item: I_KeyStat, index: number) => renderStat(item, index))}</div>
        </Container>
    )
}

export default KeyStats
