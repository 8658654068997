import React from 'react'

import { I_Component } from 'common/types/Component'
import { Container } from 'components/Container'

export interface TextProps extends I_Component {
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span'
    content: string
    classes?: string
}

export const Text: React.FC<TextProps> = ({ content, tag, classes, paddingTop, paddingBottom }) => {
    if (typeof content === 'string') {
        const TextTag = `${tag}` as keyof JSX.IntrinsicElements
        const splittedContent = content.split('%')

        return (
            <TextTag className={`${classes ? `${classes}` : ''} gap-t-${paddingTop} gap-b-${paddingBottom}`}>
                {splittedContent.map((item, index) => (
                    <React.Fragment key={index}>
                        {item}
                        {index < splittedContent.length - 1 && <br />}
                    </React.Fragment>
                ))}
            </TextTag>
        )
    } else {
        return <div />
    }
}
